<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Question details" :modal="true" class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="title">Title</label>
          <InputText id="title" v-model.trim="entityData.title" required="true" autofocus :class="{'p-invalid': submitted && !entityData.title}" />
          <small class="p-invalid" v-if="submitted && !entityData.title">Title is required.</small>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="key">Key</label>
          <InputText id="key" v-model.trim="entityData.key" required="true" autofocus :class="{'p-invalid': submitted && !entityData.key}" />
          <small class="p-invalid" v-if="submitted && !entityData.key">Key is required.</small>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="question_key" class="mb-3">Question</label>
          <Dropdown id="question_key" v-model="entityData.question_key" :options="questions" option-label="title" placeholder="Select a question" option-value="code">
          </Dropdown>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="value">Factor</label>
          <InputText id="value" v-model.trim="entityData.value" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="comparator">Comparator</label>
          <Dropdown id="comparator" v-model="entityData.comparator" :options="comparators" option-label="label" placeholder="Select a comparator" option-value="value">
          </Dropdown>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="value_type">Value type</label>
          <Dropdown id="value_type" v-model="entityData.value_type" :options="valueTypes" option-label="label" placeholder="Select a value type" option-value="value">
          </Dropdown>
        </div>
      </div>
    </div>

    <div v-if="entityData.comparator === 'plain'" class="grid">
      <div class="col-12 mb-2 lg:col-12 lg:mb-0">
        <label for="compareValue">Compare value</label>
        <InputText id="compareValue" v-model="compareValue" autofocus/>
      </div>
    </div>

    <div v-if="entityData.comparator === 'range'" class="grid">
      <div class="col-12 md:col-6">
        <label for="compareValueMin">Compare min</label>
        <InputText id="compareValueMin" v-model="compareValueMin" autofocus/>
      </div>
      <div class="col-12 md:col-6">
        <label for="compareValueMax">Compare max</label>
        <InputText id="compareValueMax" v-model="compareValueMax" autofocus/>
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>
  </Dialog>
</template>

<script>

import FactorService from "@/service/FactorService";
import QuestionService from "@/service/QuestionService";

export default {
  name: "FactorForm",
  emits: ['hideDialog','saved'],
  props: {
    entity: Object,
    dialog: Boolean,
    calculatorId: String,
  },
  questionService: null,
  entityService: null,
  mounted() {
    this.questionService.fetchAll().then(data => this.questions = data.data);
    if (this.entity.id) {
      if (this.entity.comparator === 'range') {
        this.compareValueMax = this.entity.compare_value.max;
        this.compareValueMin = this.entity.compare_value.min;
      }
    }
  },

  computed: {
    visible: {
      get() { return this.dialog },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    entityData: {
      get() { return this.entity },
    },
    compareValue: {
      get() {
        return this.entityData.compare_value.value ?? '';
      },
      set(value) {
        this.entityData.compare_value.value = value;
      }
    },
    compareValueMin: {
      get() {
        return this.entityData.compare_value.min ?? '';
      },
      set(value) {
        this.entityData.compare_value.min = value;
      }
    },
    compareValueMax: {
      get() {
        return this.entityData.compare_value.max ?? '';
      },
      set(value) {
        this.entityData.compare_value.max = value;
      }
    },
  },

  created() {
    this.questionService = new QuestionService();
    this.entityService = new FactorService();
  },

  data() {
    return {
      submitted: false,
      questions: [],
      comparators: [
        {
          label: 'Plain',
          value: 'plain'
        },
        {
          label: 'Range',
          value: 'range'
        },
      ],
      valueTypes: [
        {
          label: 'Absolute',
          value: 'absolute'
        },
        {
          label: 'Relative',
          value: 'relative'
        },
      ],
    }
  },

  methods: {
    save() {
      this.submitted = true;
      this.entityData.price_calculator_id = this.calculatorId;

      if (this.entity.id) {
        this.entityService.update(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Question updated', life: 3000});
          this.$emit('saved', response);
        });
      } else {
        this.entityData.factor_type = 'factor';
        this.entityService.create(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Question created', life: 3000});
          this.$emit('saved', response);
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
